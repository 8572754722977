import MenuEventBeforeBook from "./MenuEventBeforeBook";
import MenuEventAfterBook from "./MenuEventAfterBook";
import MenuEventCreater from "./MenuEventCreater";

type Props = {
  bgColor: any;
  styletext: any;
  opendMenu: any;
  PostBBS: any;
  BBS: any;
  eventData: any;
  eventParticipantsList: any;
  content: any;
  setContent: any;
  cancelUser: any;
  bookUser: any;
  isParticipation: any; //イベントに参加するかどうか
  userInfo: any;
};

const EventView: React.VFC<Props> = ({
  bgColor,
  styletext,
  opendMenu,
  PostBBS,
  BBS,
  eventData,
  eventParticipantsList,
  content,
  setContent,
  cancelUser,
  bookUser,
  isParticipation,
  userInfo,
}: Props) => {
  return (
    <>
      {opendMenu === "creater" ? (
        <MenuEventCreater bgColor={bgColor} />
      ) : opendMenu === "beforeBook" ? (
        <MenuEventBeforeBook
          bgColor={bgColor}
          PostBBS={PostBBS}
          BBS={BBS}
          eventData={eventData}
          //   eventTitle={eventTitle}
          //   eventDateString={eventDateString}
          eventParticipantsList={eventParticipantsList}
          content={content}
          setContent={setContent}
          cancelUser={cancelUser}
          bookUser={bookUser}
          isParticipation={isParticipation}
          userInfo={userInfo}
        />
      ) : opendMenu === "afterBook" ? (
        <MenuEventAfterBook
          bgColor={bgColor}
          PostBBS={PostBBS}
          BBS={BBS}
          eventData={eventData}
          //   eventTitle={eventTitle}
          //   eventDateString={eventDateString}
          eventParticipantsList={eventParticipantsList}
          content={content}
          setContent={setContent}
          cancelUser={cancelUser}
          bookUser={bookUser}
          isParticipation={isParticipation}
          userInfo={userInfo}
        />
      ) : (
        <div>ここは表示されないはず</div>
      )}
    </>
  );
};
export default EventView;
