import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";

import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  CardHeader,
  Link,
  IconButton,
} from "@mui/material";

import { otherApp } from "../../../common/firebaseHelper/firebase";

import { addEvent } from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";
import { getUserPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";
import {
  setUserPrivateBook,
  getUserPrivateBook,
  deleteUserPrivateBook,
  getUserPrivatePlan,
  setUserPrivatePlan,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPrivate";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Userpage from "../Userpage/Userpage";

import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import CreateEventView from "./CreateEventView";

import ChoiceEki from "./ChoiceEki";

type Props = {
  bgColor: any;
  styletext: any;
  // avatar: any;
  // isLogin: any;
  userInfo: any;

  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
};

const CreateEvent: React.FC<Props> = ({
  bgColor,
  styletext,
  datas,
  setDatas,
  routeList,
  // isLogin,
  // avatar,
  userInfo,
}) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");

  useEffect(() => {
    const f = async () => {};
    f();
  }, []);

  const centerStyle = {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    height: "100vh", //画面の高さいっぱいに表示する場合
  };

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };

  return (
    <>
      {" "}
      <Navbar
        bgColor={bgColor}
        styletext={styletext}
        // setOpendMenu={setOpendMenu}

        userInfo={userInfo}
      />
      <div
        style={
          styletext.windowWidth > 1200 ? styles1.container : styles2.container
        }
      >
        <CreateEventView
          bgColor={bgColor}
          styletext={styletext}
          datas={datas}
          setDatas={setDatas}
          routeList={routeList}
          userInfo={userInfo}
        />
      </div>
      <Footer bgColor={bgColor} styletext={styletext} />
    </>
  );
};

export default CreateEvent;
