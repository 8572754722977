import { firebaseApp } from "./firebase";
import {
  doc,
  collection,
  getFirestore,
  runTransaction,
  query,
  getDocs,
  getDoc,
  orderBy,
  limit,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  documentId,
  where,
  increment,
} from "firebase/firestore";

export async function addEvent(data: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q1 = doc(db, "setting", "Event");

  try {
    const docRef2 = await runTransaction(db, async (transaction) => {
      // ドキュメントの現在の値を取得
      const updatedDocRef = await transaction.get(q1);
      const currentEventCount = updatedDocRef.data()?.EventCount || 0;

      // カウントをインクリメント
      const updateEventCount = currentEventCount + 1;

      // EventBBSCountを更新
      transaction.update(q1, { EventCount: updateEventCount });

      // BBSコレクションに新しいドキュメントを追加
      let q = collection(db, "Event");
      const docRef2 = await addDoc(q, { ...data, num: updateEventCount });

      return docRef2;
    });

    return docRef2;
  } catch (e) {
    console.error("Transaction failed: ", e);
    throw e;
  }
}

export async function getEvents(): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "Event"), orderBy("priority"), limit(50));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function updateEvent(eventId: any, data: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "Event", eventId);
  const docRef = await updateDoc(q, data);
  return docRef;
}

export async function deleteEvent(eventId: any): Promise<void> {
  const db = getFirestore(firebaseApp);
  const eventRef = doc(db, "Event", eventId);
  await deleteDoc(eventRef);
}

export async function getEvent(eventId: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "Event", eventId);
  let docRef = await getDoc(q);
  return docRef;
}

export async function updateEventParticipants(
  eventId: any,
  userId: any,
  data: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "Event", eventId, "Participants", userId);
  const docRef = await updateDoc(q, data);
  return docRef;
}

export async function setEventParticipants(
  eventId: any,
  userId: any,
  data: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "Event", eventId, "Participants", userId);
  const docRef = await setDoc(q, data);
  return docRef;
}

export async function deleteEventParticipants(
  eventId: any,
  userId: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let docRef = doc(db, "Event", eventId, "Participants", userId);
  await deleteDoc(docRef);
  return true;
}

export async function getEventParticipants(eventId: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "Event", eventId, "Participants"), limit(50));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function addEventBBS(eventId: any, data: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q1 = doc(db, "Event", eventId);

  try {
    const docRef2 = await runTransaction(db, async (transaction) => {
      // ドキュメントの現在の値を取得
      const updatedDocRef = await transaction.get(q1);
      const currentEventBBSCount = updatedDocRef.data()?.EventBBSCount || 0;

      // カウントをインクリメント
      const updateEventBBSCount = currentEventBBSCount + 1;

      // EventBBSCountを更新
      transaction.update(q1, { EventBBSCount: updateEventBBSCount });

      // BBSコレクションに新しいドキュメントを追加
      let q = collection(db, "Event", eventId, "BBS");
      const docRef2 = await addDoc(q, { ...data, num: updateEventBBSCount });

      return docRef2;
    });

    return docRef2;
  } catch (e) {
    console.error("Transaction failed: ", e);
    throw e;
  }
}

export async function getEventBBSs(eventId: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "Event", eventId, "BBS"),
    orderBy("num"),
    limit(50)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}
