import { firebaseApp } from "./firebase";
import {
  doc,
  collection,
  getFirestore,
  runTransaction,
  query,
  getDocs,
  orderBy,
  limit,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  documentId,
  where,
} from "firebase/firestore";

export async function getGatheringPlaces(id: string): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "YamanoteRoute", id, "GatheringPlace"),
    orderBy("num"),
    limit(10)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function addGatheringPlace(id: string, data: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = collection(db, "YamanoteRoute", id, "GatheringPlace");
  let docRef = await addDoc(q, data);
  return docRef;
}

export async function updateGatheringPlace(
  id: string,
  id2: string,
  data: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "YamanoteRoute", id, "GatheringPlace", id2);
  let docRef = await updateDoc(q, data);
  return docRef;
}

export async function deleteGatheringPlace(
  id: string,
  id2: string
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "YamanoteRoute", id, "GatheringPlace", id2);
  await deleteDoc(q);
  return true;
}
