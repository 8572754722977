import React from "react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";

import { Button, TextField, Checkbox, Link } from "@mui/material";

import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";

import ChoiceEki from "./ChoiceEki";
import { markdown } from "./teikei";

type Props = {
  bgColor: any;
  styletext: any;
  // avatar: any;
  // isLogin: any;
  userInfo: any;

  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];

  choiceEki: any;
  setChoiceEki: any;

  choiceDate: any;
  setChoiceDate: any;
  choiceTime: any;
  setChoiceTime: any;
  title: any;
  setTitle: any;
  handleChangeTerm: any;
  termcheck: any;

  setOpendMenu: any;
};

const CreateEventWrite: React.FC<Props> = ({
  bgColor,
  styletext,
  datas,
  setDatas,
  routeList,
  // isLogin,
  // avatar,
  userInfo,

  choiceEki,
  setChoiceEki,

  choiceDate,
  setChoiceDate,
  choiceTime,
  setChoiceTime,
  title,
  setTitle,
  handleChangeTerm,
  termcheck,

  setOpendMenu,
}) => {
  return (
    <div
      style={{
        fontFamily: "'Roboto', sans-serif",
        // backgroundColor: "#f4f4f4",
        color: "#333",
        margin: 0,
        padding: "20px",
      }}
    >
      <br />
      <div style={{ textAlign: "center", backgroundColor: bgColor.back05 }}>
        🔳 駅選択
      </div>
      <ChoiceEki
        bgColor={bgColor}
        styletext={styletext}
        datas={datas}
        setDatas={setDatas}
        routeList={routeList}
        setChoiceEki={setChoiceEki}
      />
      <br />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <br />

        <div style={{ textAlign: "center", backgroundColor: bgColor.back05 }}>
          🔳 日付選択
        </div>
        <br />
        <br />
        <DateCalendar
          value={choiceDate}
          onChange={(newValue) => setChoiceDate(newValue)}
        />

        <div style={{ textAlign: "center", backgroundColor: bgColor.back05 }}>
          🔳 開始時間選択
        </div>
        <StaticTimePicker
          displayStaticWrapperAs="mobile" // モバイル向けレイアウト
          orientation="portrait"
          value={choiceTime}
          onChange={(newValue) => setChoiceTime(newValue)}
          slotProps={{
            toolbar: {
              hidden: false, // ツールバーを表示
              sx: {
                display: "flex",
                justifyContent: "center", // 要素を中央揃え
                alignItems: "center", // 垂直方向も中央揃え
              },
            },
            actionBar: { actions: [] }, // アクションバーをなくす
          }}
        />
      </LocalizationProvider>
      {/* <br />
      <br />
      <div style={{ textAlign: "center", backgroundColor: bgColor.back05 }}>
        🔳 コメント入力
      </div>
      <br />
      <TextField
        label="コメント"
        name="Name"
        // value={title}
        value={markdown}
        variant="standard"
        fullWidth
        margin="dense"
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        sx={{
          input: {
            color: "#000000",
          },
        }}
        style={{
          border: "#000000",
        }}
        InputLabelProps={{
          style: {
            color: "#000000",
          }, // ここに希望の色を指定
        }}
      /> */}
      {/* <br /> <br />
      <div style={{ textAlign: "center", backgroundColor: bgColor.back05 }}>
        🔳 利用規約
      </div>
      <br />
      <div
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        <Checkbox
          style={{
            color: bgColor.back11,
          }}
          inputProps={{ "aria-label": "secondary checkbox" }}
          onClick={handleChangeTerm}
          checked={termcheck}
        />
        <Link color="inherit" href="http://lonpa.net/Terms" target="_blank">
          利用規約
        </Link>
        に同意します
      </div> */}
      <br />
      <br />
      <br />
      <Button
        style={{
          display: "inline-block",
          padding: "10px 10px",
          fontSize: "18px",
          color: "#fff",
          backgroundColor: true ? bgColor.back06 : bgColor.back05,
          textDecoration: "none",
          borderRadius: "5px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "background-color 0.3s ease",
          width: "100%",
        }}
        disabled={false}
        onClick={() => {
          setOpendMenu("two");
        }}
      >
        作成
      </Button>
      <br />
      <br />
    </div>
  );
};
export default CreateEventWrite;
