import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

type Props = {
  bgColor: any;
  styletext: any;
  datas: any;
  setDatas: React.Dispatch<React.SetStateAction<any>>;
  routeList: any;
  setChoiceEki: any;
};

const ChoiceEki: React.FC<Props> = ({
  bgColor,
  styletext,
  datas,
  setDatas,
  routeList,
  setChoiceEki,
}) => {
  const navigate = useNavigate();
  const [currentAngle, setCurrentAngle] = useState({
    angle: 1080,
    num: 0,
    text: "東京",
    id: "XizOo6WOXHjMK01o6htz",
    describe: "新幹線と地下鉄の中心で日本最大の交通拠点",
  });

  const centerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //   justifyContent: "center",
    minHeight: "10vh",
    overflow: "hidden", // はみ出た部分を無視
  };
  //巨大な円のサイズ
  const circularMenuStyle: React.CSSProperties = {
    position: "relative",
    width: "100%",
    height: "20vh",
    // right: "25px",
    overflow: "hidden", // はみ出た部分を無視
  };

  const stationItemStyle: React.CSSProperties = {
    position: "absolute",
    top: "40vh",
    left: "46%",
    transformOrigin: "center center",
    transition: "transform 0.5s ease-in-out, color 0.5s ease-in-out",
    overflow: "hidden", // はみ出た部分を無視
  };

  //赤字の選択駅
  const centerExStyle: React.CSSProperties = {
    position: "absolute",
    top: "14vh",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center", // Centers text horizontally
  };

  //内回り・外回りボタン
  const centerExStyle2: React.CSSProperties = {
    position: "absolute",
    top: "17vh",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center", // Centers text horizontally
    width: styletext.displayWidth * 0.7,
  };

  const cellStyle1 = {
    height: "10vh",
  };
  const cellStyle2 = {
    height: "10vh",
  };
  const cellStyle3 = {
    height: "2vh",
  };

  const cellStyle4 = {
    height: "10vh",
    gap: "10px",
  };

  const wordsize = "18px";

  const nextStation = () => {
    let nextnum = currentAngle.num;
    let nextangle = currentAngle.angle;
    do {
      nextnum = (nextnum - 1 + routeList.length) % routeList.length;
      nextangle = nextangle + 360 / routeList.length;
    } while (!routeList[nextnum].isActive);

    setCurrentAngle((prev) => ({
      angle: nextangle,
      num: nextnum,
      text: routeList[nextnum].text,
      id: routeList[nextnum].id,
      describe: routeList[nextnum].describe,
    }));
    setChoiceEki({
      num: nextnum,
      text: routeList[nextnum].text,
      id: routeList[nextnum].id,
      describe: routeList[nextnum].describe,
    });
  };
  const prevStation = () => {
    let nextnum = currentAngle.num;
    let nextangle = currentAngle.angle;
    do {
      nextnum = (nextnum + 1) % routeList.length;
      nextangle = nextangle - 360 / routeList.length;
    } while (!routeList[nextnum].isActive);

    setCurrentAngle((prev) => ({
      angle: nextangle,
      num: nextnum,
      text: routeList[nextnum].text,
      id: routeList[nextnum].id,
      describe: routeList[nextnum].describe,
    }));
    setChoiceEki({
      num: nextnum,
      text: routeList[nextnum].text,
      id: routeList[nextnum].id,
      describe: routeList[nextnum].describe,
    });
  };

  return (
    <div style={centerStyle}>
      <br />
      <div style={circularMenuStyle}>
        {routeList.map((item: any, index: any) => {
          const angle = (360 / routeList.length) * index + currentAngle.angle;
          const reverseIndex =
            (routeList.length -
              (Math.round(currentAngle.angle / (360 / routeList.length)) %
                routeList.length)) %
            routeList.length;
          // console.log(reverseIndex, routeList.length);
          const isSelected = index === reverseIndex;
          return (
            <div
              key={item.id}
              style={{
                ...stationItemStyle,
                transform: `rotate(${angle}deg) translate(0, -35vh) rotate(-${angle}deg)`,
              }}
            >
              <Button
                variant="outlined"
                style={{
                  fontSize: isSelected ? wordsize : wordsize,
                  marginTop: isSelected ? "0px" : "0px",

                  padding: "1px 2px",
                  minWidth: "auto",
                  color: !item.isActive
                    ? "#D3D3D3"
                    : isSelected
                    ? "white"
                    : "black",
                  border: "none",
                  textAlign: "center", // 中央揃え
                  display: "flex", // フレックスボックスを使用
                  justifyContent: "center", // 水平方向の中央揃え
                }}
                disabled={true}
              >
                {item.text}
              </Button>
            </div>
          );
        })}
        <div style={centerExStyle}>
          <table>
            <tbody>
              <tr>
                <td style={cellStyle1}>
                  <div
                    style={{
                      color: "red",
                      fontSize: "26px",
                      width: styletext.displayWidth,
                    }}
                  >
                    {currentAngle.text}
                  </div>
                </td>
              </tr>
              <tr>
                <td style={cellStyle2}></td>
              </tr>

              <tr>
                <td style={cellStyle4}>
                  <table style={centerExStyle2}>
                    <tbody>
                      <tr>
                        <td>
                          <Button
                            variant="outlined"
                            style={{
                              backgroundColor: bgColor.back01,
                              color: bgColor.text04.fontColor,
                              border: "none",
                              fontWeight: "bold",
                              fontSize: 20,
                            }}
                            onClick={nextStation}
                          >
                            ＜内回り
                          </Button>
                        </td>
                        <td>
                          <div></div>
                        </td>
                        <td>
                          <Button
                            variant="outlined"
                            style={{
                              backgroundColor: bgColor.back01,
                              color: bgColor.text04.fontColor,
                              border: "none",
                              fontWeight: "bold",
                              fontSize: 20,
                            }}
                            onClick={prevStation}
                          >
                            外回り＞
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ChoiceEki;
