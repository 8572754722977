import React from "react";

import { IconButton, Button } from "@mui/material";

import Icon from "./bouken.png";
import { useNavigate } from "react-router-dom";

type Props = {
  bgColor: any;
};

const Logobar: React.VFC<Props> = ({ bgColor }: Props) => {
  const navigate = useNavigate();
  return (
    <div>
      {/* タイトルロゴ */}
      <div style={{ height: "70px" }}>
        <IconButton
          aria-haspopup="true"
          onClick={() => {
            window.scrollTo(0, 0);
            navigate("/Book");
          }}
        >
          <div style={{ height: "55px" }}></div>
          <img src={Icon} alt="アイコン" height="48" width="90" />
        </IconButton>
      </div>
    </div>
  );
};
export default Logobar;
