import { otherApp } from "./firebase";
import {
  doc,
  collection,
  getFirestore,
  runTransaction,
  query,
  getDocs,
  getDoc,
  orderBy,
  limit,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  documentId,
  where,
} from "firebase/firestore";

export async function createCheckoutSessions(id1: any, data: any) {
  const db = getFirestore(otherApp);
  const col = collection(db, "customers", id1, "checkout_sessions");
  const docRef = await addDoc(col, data);
  return docRef;
}

export async function getCustomersPayment(id1: any) {
  const db = getFirestore(otherApp);
  let querySnapshot;
  try {
    const q = query(collection(db, "customers", id1, "payments"));
    querySnapshot = await getDocs(q);
  } catch {
    querySnapshot = false;
  }
  return querySnapshot;
}

export async function getCustomersSubscriptions(id1: any) {
  const db = getFirestore(otherApp);
  let querySnapshot;
  try {
    const q = query(collection(db, "customers", id1, "subscriptions"));
    querySnapshot = await getDocs(q);
  } catch {
    querySnapshot = false;
  }
  return querySnapshot;
}
