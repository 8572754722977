import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Userpage from "../Userpage/Userpage";

type Props = {
  bgColor: any;
  styletext: any;
  // isLogin: any;
  userInfo: any;
  incrementChangeCount: any;
  setOpendMenu: any;
};

const MenuProfile: React.VFC<Props> = ({
  bgColor,
  styletext,
  // isLogin,
  userInfo,

  incrementChangeCount,
  setOpendMenu,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div>
      <>
        {userInfo.isLogin ? (
          <Userpage
            bgColor={bgColor}
            styletext={styletext}
            incrementChangeCount={incrementChangeCount}
          />
        ) : (
          <>
            <br />
            profileのページです
            <br />
            まずは
            <Button
              style={{
                display: "inline-block",
                padding: "10px 10px",
                fontSize: "18px",
                color: "#fff",
                backgroundColor: "#CC9900",
                textDecoration: "none",
                borderRadius: "5px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s ease",
              }}
              onClick={() => {
                setOpendMenu("signin");
              }}
            >
              ログイン
            </Button>
            しましょう
          </>
        )}
      </>
    </div>
  );
};

export default MenuProfile;
