import { Dispatch, SetStateAction, useEffect } from "react";

import { Datas, Route } from "../../../common/types";
import { useNavigate } from "react-router-dom";

import { getDatas } from "../../../common/firebaseHelper/FirebaseTransactionHelper";

import { Button } from "@mui/material";

type Props = {
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
};
const centerStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  justifyContent: "center",
  //   height: "100vh", //画面の高さいっぱいに表示する場合
};
const TsukaikataView: React.FC<Props> = ({ datas, setDatas, routeList }) => {
  const navigate = useNavigate();
  const nl2br = (str: string) => {
    return str.replace(/\n/g, "<br />");
  };
  return (
    <div style={centerStyle}>
      <h1 style={{ color: "skyblue", fontSize: "26px" }}>
        散歩イベントの楽しみ方
      </h1>
      <p
        dangerouslySetInnerHTML={{
          __html: nl2br(
            `
            1. 最大4人のグループを作りましょう

            2. グループリーダーを決めましょう

            3. リーダーは 駅を選択してガチャをひきましょう

            4. リーダーは QRコードをメンバ共有しましょう

            5. メンバと、達成したいものを選びましょう

            6. 指令達成を目指して歩きましょう
            `
          ),
        }}
      ></p>
      <br />
    </div>
  );
};

export default TsukaikataView;
