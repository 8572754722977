import { useRef } from "react";
import { Avatar } from "@mui/material";
import Pica from "pica";

import { otherApp } from "../../../common/firebaseHelper/firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { updateOrSetUserPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";

import Compressor from "compressorjs";

export default function Useravatar(props) {
  const pica = new Pica();
  const inputRef = useRef(null);
  const fileUpload = () => {
    inputRef.current.click();
  };
  //uploadできる画像のバイト数のリミット
  const limitUpdateByte = 300000;

  async function onSubmit(event) {
    event.preventDefault();
    if (!props.user) {
      console.log("ログインしていません");
      return;
    }
    if (typeof event.target.files[0] === "undefined") {
      console.log("ファイルが選択されていません");
      return;
    }
    props.setOpen(true);
    let avatarImage = event.target.files[0];

    // 画像のリサイズ
    const canvas = document.createElement("canvas");
    const img = new Image();
    img.src = URL.createObjectURL(avatarImage);

    img.onload = async () => {
      canvas.width = 100; // リサイズ後の幅
      canvas.height = 100; // リサイズ後の高さ

      try {
        const resizedImage = await pica.resize(img, canvas);
        canvas.toBlob((blob) => {
          // 画像を圧縮

          new Compressor(blob, {
            quality: 0.6,
            success(result) {
              const image = result;
              if (image.size < limitUpdateByte) {
                const storage = getStorage(otherApp);
                const storageRef = ref(
                  storage,
                  "useravatar/" + props.user.uid + "/avatar"
                ); //どのフォルダの配下に入れるかを設定
                const uploadTask = uploadBytesResumable(storageRef, image); //ファイル名
                //ファイルをアップロードする
                uploadTask.on("state_changed", () => {
                  getDownloadURL(uploadTask.snapshot.ref).then(
                    (downloadURL) => {
                      const f = async () => {
                        await updateOrSetUserPublic(props.user.uid, {
                          imageurl: downloadURL,
                        });
                        props.setimageurl(downloadURL);
                        props.setOpen(false);
                      };
                      f();
                    }
                  );
                });
              } else {
                alert("容量が大きすぎます。やり直してください");
                props.setOpen(false);
              }
            },
            error(err) {
              console.log(err);
              return;
            },
          });
        }, "image/jpeg");
      } catch (error) {
        console.log("リサイズエラー:", error);
        props.setOpen(false);
      }
    };
  }

  return (
    <>
      <Avatar
        onClick={fileUpload}
        src={props.imageurl}
        sx={{
          height: 100,
          width: 100,
        }}
      />
      <input
        hidden
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={onSubmit}
      />
    </>
  );
}
