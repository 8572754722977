import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useState } from "react";

import { Datas, Route } from "../../common/types";

import typepic1 from "./bouken.jpg";

type Props = {
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
};

const centerStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh", //画面の高さいっぱいに表示する場合
};

const Top: React.FC<Props> = ({ datas, setDatas }) => {
  const navigate = useNavigate();
  const [checkMenteNum, setCheckMenteNum] = useState(0);
  //管理者画面へ遷移
  const checkMente = (selectnum: number) => {
    setCheckMenteNum(checkMenteNum + selectnum);

    if (checkMenteNum === 302) {
      navigate("/DeveloperView");
    }
  };
  return (
    <div style={centerStyle}>
      <>
        <br />
        {/* <h1 style={{ color: "skyblue", fontSize: "36px" }}>山手散歩</h1> */}
        <img
          src={typepic1}
          style={{
            width: "100%",
            maxWidth: "600px",
            maxHeight: "600px",
            objectFit: "contain",
          }}
        ></img>
        <br />

        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("/Ekisentaku");
          }}
          style={{ fontSize: 20, width: "80%" }}
        >
          行き先ガチャ
        </Button>
        <br />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("/Book");
          }}
          style={{ fontSize: 20, width: "80%" }}
        >
          イベント予約
        </Button>
        <br />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("/Tsukaikata");
          }}
          style={{ fontSize: 20, width: "80%" }}
        >
          使い方
        </Button>
        <br />

        <table>
          <tbody>
            <tr>
              <td>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    checkMente(1);
                  }}
                  style={{
                    fontSize: 20,
                    color: "white", // 文字の色を白に設定
                    borderColor: "white", // 枠線の色を白に設定
                  }}
                >
                  A
                </Button>
              </td>
              <td>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    checkMente(100);
                  }}
                  style={{
                    fontSize: 20,
                    color: "white", // 文字の色を白に設定
                    borderColor: "white", // 枠線の色を白に設定
                  }}
                >
                  B
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </div>
  );
};

export default Top;
