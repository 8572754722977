import { Route, BrowserRouter, Routes } from "react-router-dom";
import Top from "./components/Top/Top";
import Ekisentaku from "./components/views/Ekisentaku/Ekisentaku";
import Shizigatya from "./components/views/Shizigatya/Shizigatya";
import Gatyaresult from "./components/views/Gatyaresult/Gatyaresult";
import Sanponaiyou from "./components/views/Sanponaiyou/Sanponaiyou";
import Tsukaikata from "./components/views/Tsukaikata/Tsukaikata";
import Developer from "./components/views/Developer/Developer";
import Book from "./components/views/Book/Book";
import Userpage from "./components/views/Userpage/Userpage";
import Event from "./components/views/Event/Event";
import CreateEvent from "./components/views/CreateEvent/CreateEvent";
import EditEvent from "./components/views/EditEvent/EditEvent";
import Point from "./components/Point/Point";

type Props = {
  bgColor: any;
  styletext: any;
  datas: any;
  setDatas: any;
  routeList: any;
  userInfo: any;
};

const Router: React.VFC<Props> = ({
  bgColor,
  styletext,
  datas,
  setDatas,
  routeList,
  userInfo,
}: Props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Book
              bgColor={bgColor}
              styletext={styletext}
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
              userInfo={userInfo}
            />
          }
        />
        <Route
          path="/Top"
          element={<Top datas={datas} setDatas={setDatas} />}
        />
        <Route
          path="/ekisentaku"
          element={
            <Ekisentaku
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
              bgColor={bgColor}
              styletext={styletext}
              userInfo={userInfo}
            />
          }
        />
        <Route
          path="/shizigatya/:ekiid"
          element={
            <Shizigatya
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
              bgColor={bgColor}
              styletext={styletext}
              userInfo={userInfo}
            />
          }
        />
        <Route
          path="/gatyaresult/:ekiid/:id1/:id2/:id3"
          element={
            <Gatyaresult
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
              bgColor={bgColor}
              styletext={styletext}
              userInfo={userInfo}
            />
          }
        />
        <Route
          path="/sanponaiyou/:ekiid/:id1"
          element={
            <Sanponaiyou
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
            />
          }
        />
        <Route
          path="/tsukaikata"
          element={
            <Tsukaikata
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
              bgColor={bgColor}
              styletext={styletext}
              userInfo={userInfo}
            />
          }
        />
        <Route
          path="/DeveloperView"
          element={
            <Developer
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
              bgColor={bgColor}
              styletext={styletext}
              userInfo={userInfo}
            />
          }
        />
        <Route
          path="/Book"
          element={
            <Book
              bgColor={bgColor}
              styletext={styletext}
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
              userInfo={userInfo}
            />
          }
        />
        <Route
          path="/Book/:opendmenu"
          element={
            <Book
              bgColor={bgColor}
              styletext={styletext}
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
              userInfo={userInfo}
            />
          }
        />

        <Route
          path="/Userpage"
          element={
            <Userpage
              bgColor={bgColor}
              styletext={styletext}
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
            />
          }
        />
        <Route
          path="/CreateEvent"
          element={
            <CreateEvent
              bgColor={bgColor}
              styletext={styletext}
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
              userInfo={userInfo}
            />
          }
        />
        <Route
          path="/Event/:eventid"
          element={
            <Event
              bgColor={bgColor}
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
              styletext={styletext}
              userInfo={userInfo}
            />
          }
        />

        <Route
          path="/EditEvent/:eventid"
          element={
            <EditEvent
              bgColor={bgColor}
              styletext={styletext}
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
              userInfo={userInfo}
            />
          }
        />

        <Route
          path="/Point"
          element={
            <Point
              bgColor={bgColor}
              styletext={styletext}
              userInfo={userInfo}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
