import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  bgColor: any;
  styletext: any;
  // isLogin: any;
  userInfo: any;
  eventJoinList: any;
  eventJoinPastList: any;
  eventJoinFutureList: any;
  cancelUser: any;
  setOpendMenu: any;
};

const MenuJoin: React.VFC<Props> = ({
  bgColor,
  styletext,
  // isLogin,
  userInfo,

  eventJoinList,
  eventJoinPastList,
  eventJoinFutureList,
  cancelUser,
  setOpendMenu,
}: Props) => {
  const navigate = useNavigate();
  const cellStyle: React.CSSProperties = {
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "14px",
    textAlign: "center", // 水平方向の中央揃え
    // width: "fit-content", // コンテンツ幅に合わせる
    marginLeft: "auto", // 左右中央に配置
    marginRight: "auto",
  };
  return (
    <div>
      <div style={{ textAlign: "center", backgroundColor: bgColor.back05 }}>
        🔳 参加予定の一覧
      </div>
      <br />
      {userInfo.isLogin ? (
        <>
          {console.log(eventJoinList)}
          <table
            style={{
              width: styletext.displayWidth,
            }}
          >
            <tbody>
              {eventJoinList.length === 0 ? (
                <tr>
                  <td style={cellStyle}>イベントに参加していません。</td>
                </tr>
              ) : (
                <>
                  {eventJoinList.map((item: any, index: any) => (
                    <tr>
                      <td style={cellStyle}>{item.title}</td>

                      <td style={cellStyle}>
                        <Button
                          style={{
                            display: "inline-block",
                            padding: "10px 10px",
                            fontSize: "18px",
                            color: "#fff",
                            backgroundColor: bgColor.back06,
                            textDecoration: "none",
                            borderRadius: "5px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                          }}
                          onClick={() => {
                            navigate("/Event/" + item.id);
                          }}
                        >
                          詳細
                        </Button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <br />
          <div style={{ textAlign: "center", backgroundColor: bgColor.back05 }}>
            🔳 過去の参加一覧
          </div>
          <table
            style={{
              width: styletext.displayWidth,
            }}
          >
            <tbody>
              {eventJoinPastList.length === 0 ? (
                <tr>
                  <td style={cellStyle}>イベントに参加していません。</td>
                </tr>
              ) : (
                <>
                  {eventJoinPastList.map((item: any, index: any) => (
                    <tr>
                      <td style={cellStyle}>{item.title}</td>

                      <td style={cellStyle}>
                        <Button
                          style={{
                            display: "inline-block",
                            padding: "10px 10px",
                            fontSize: "18px",
                            color: "#fff",
                            backgroundColor: bgColor.back06,
                            textDecoration: "none",
                            borderRadius: "5px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                          }}
                          onClick={() => {
                            navigate("/Event/" + item.id);
                          }}
                        >
                          詳細
                        </Button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <br />
          {/* <div style={{ textAlign: "center" }}>
            過去参加イベントがありません
          </div> */}
          <br />
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <br />
          まずは
          <Button
            style={{
              display: "inline-block",
              padding: "10px 10px",
              fontSize: "18px",
              color: "#fff",
              backgroundColor: "#CC9900",
              textDecoration: "none",
              borderRadius: "5px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              setOpendMenu("signin");
            }}
          >
            ログイン
          </Button>
          しましょう
        </div>
      )}
    </div>
  );
};

export default MenuJoin;
