import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, SwipeableDrawer } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";

import SearchIcon from "@mui/icons-material/Search";
import CreateIcon from "@mui/icons-material/Create";
import Logobar from "./Logobar";
import MenuIcon from "@mui/icons-material/Menu";
import Loadmap from "./Loadmap/Loadmap";

type Props = {
  bgColor: any;
  styletext: any;
  // setOpendMenu: any;
  // avatar: any;
  // isLogin: any;
  userInfo: any;
};

const Navbar: React.VFC<Props> = ({
  bgColor,
  styletext,
  // setOpendMenu,
  // avatar,
  // isLogin,
  userInfo,
}: Props) => {
  const navigate = useNavigate();
  const buttonHeight = "70px";
  const textMarginTop = "30px";
  const [isSwipeOpen, setIsSwipeOpen] = useState(false);
  return (
    <div
      style={{
        backgroundColor: bgColor.back04,
        height: buttonHeight,
      }}
    >
      <div style={{ width: "110px", position: "fixed", zIndex: 100 }}>
        <Logobar bgColor={bgColor} />
      </div>
      <img
        style={{
          display: "inline-block",
          width: "110px",
          backgroundColor: bgColor.back04,
        }}
      ></img>

      {/* setOpendMenu("profile"); */}

      <Button
        variant="outlined"
        onClick={() => {
          navigate("/Book/event");
          // setOpendMenu("event");
        }}
        style={{
          width: `calc(calc(100% - 110px) / 3)`,
          height: buttonHeight,
          border: "none", // 枠をなくす
          borderRadius: "0px",
        }}
      >
        <SearchIcon style={{ color: bgColor.text03.fontColor }} />
      </Button>

      <Button
        variant="outlined"
        onClick={() => {
          navigate("/Book/join");
          // setOpendMenu("join");
        }}
        style={{
          width: `calc(calc(100% - 110px) / 3)`,
          height: buttonHeight,
          border: "none", // 枠をなくす
        }}
      >
        <EventIcon style={{ color: bgColor.text03.fontColor }} />
      </Button>

      <Button
        variant="outlined"
        onClick={() => {
          setIsSwipeOpen(true);
        }}
        style={{
          width: `calc(calc(100% - 110px) / 3)`,
          height: buttonHeight,
          border: "none", // 枠をなくす
        }}
      >
        <MenuIcon style={{ color: bgColor.text03.fontColor }} />
      </Button>

      <SwipeableDrawer
        anchor={"right"}
        open={isSwipeOpen}
        onClose={() => {
          setIsSwipeOpen(false);
        }}
        onOpen={() => {
          setIsSwipeOpen(true);
        }}
        PaperProps={{
          sx: {
            backgroundColor: bgColor.back01,
            width: styletext.widthUnder / 2,
          },
        }}
      >
        <Loadmap
          bgColor={bgColor}
          setIsSwipeOpen={setIsSwipeOpen}
          userInfo={userInfo}
          buttonHeight={buttonHeight}
          textMarginTop={textMarginTop}
          // setOpendMenu={setOpendMenu}
        />
      </SwipeableDrawer>
    </div>
  );
};
export default Navbar;
