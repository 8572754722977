type Props = { bgColor: any };

const Test: React.VFC<Props> = ({ bgColor }: Props) => {
  const buttonHeight = "70px";
  const textMarginTop = "30px";
  return (
    <>
      <div
        style={{
          height: buttonHeight,
          width: "100%",
          position: "fixed",
          backgroundColor: bgColor.back04,
          zIndex: 100,
        }}
      ></div>
    </>
  );
};
export default Test;
