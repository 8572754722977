import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";

import { Datas, Route, Command } from "../../../common/types";
import ImageSettingGatheringPlace from "../../../common/lib/ImageSettingGatheringPlace";

import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
} from "@mui/material";

import { Timestamp } from "firebase/firestore"; // FirestoreのTimestamp型をインポート
import {
  getGatheringPlaces,
  addGatheringPlace,
  updateGatheringPlace,
  deleteGatheringPlace,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperGatheringPlace";

type Props = {
  bgColor: any;
  styletext: any;
  userInfo: any;
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
  text1: any;
  text2: any;
  eki_id: any;
  changing: any;
  setChanging: any;
  changeCount: number;
};

const DeveloperGatheringPlace: React.VFC<Props> = ({
  datas,
  setDatas,
  routeList,
  bgColor,
  styletext,
  userInfo,
  text1,
  text2,
  eki_id,
  changing,
  setChanging,
  changeCount,
}: Props) => {
  const [text4, settext4] = useState("");
  const [text5, settext5] = useState("");
  const [text6, settext6] = useState("");
  const [openingtime, setopeningtime] = useState<Date>(new Date(0));
  const [closingtime, setclosingtime] = useState<Date>(new Date(0));
  const [explainText, setExplainText] = useState("まずは駅を選んでね");
  const [changeType, setChangeType] = useState(true);
  const [isTextinputLock, setIsTextinputLock] = useState(true);

  const [commandList, setCommandList] = useState<Command[]>([]);
  const [imageurl, setimageurl] = useState("");
  const lineStyle = {
    display: "inline-block",
    width: "100%",
  };

  useEffect(() => {
    console.log("uE_DeveloperGatheringPlace");
    const f = async () => {
      if (eki_id) {
        getDataGatheringPlace(String(eki_id));
      } else {
        alert("エラーです");
      }
    };
    f();
  }, []);

  //指令を取得する処理
  async function getDataGatheringPlace(id: string): Promise<boolean> {
    const querySnapshot = await getGatheringPlaces(id);

    let lists: any = [];
    querySnapshot.forEach((doc: any) => {
      let openingtimenew;
      let closingtimenew;
      if (doc.data().openingtime instanceof Timestamp) {
        openingtimenew = doc.data().openingtime.toDate();
      } else {
        openingtimenew = new Date(0);
      }

      if (doc.data().closingtime instanceof Timestamp) {
        closingtimenew = doc.data().closingtime.toDate();
      } else {
        closingtimenew = new Date(0);
      }

      lists.push({
        id: doc.id,
        num: doc.data().num,
        text: doc.data().text,
        imageurl: doc.data().imageURL,
      });
    });

    setCommandList(lists);

    return true;
  }
  return (
    <>
      {commandList.length > 0 ? (
        <>
          　
          <div style={lineStyle}>
            {commandList.map((item2: any, index: any) => (
              <Button
                variant="outlined"
                key={item2.id}
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  minWidth: "auto",
                }}
                onClick={() => {
                  settext4(String(item2.num));
                  settext5(String(item2.text));
                  settext6(String(item2.id));
                  setimageurl(String(item2.imageurl));
                  setopeningtime(item2.openingtime);
                  setclosingtime(item2.closingtime);
                  setChangeType(true);
                  setChanging(false);
                  setIsTextinputLock(false);
                }}
              >
                {item2.num}:{item2.text}
              </Button>
            ))}
          </div>
          <br />
          <Button
            variant="outlined"
            onClick={() => {
              settext4(String(commandList.length));
              settext5("");
              settext6("自動生成されます");
              setopeningtime(new Date(0));
              setclosingtime(new Date(0));
              setChangeType(false);
              setChanging(false);
              setIsTextinputLock(false);
            }}
          >
            {commandList.length}:追加する
          </Button>
        </>
      ) : (
        <>
          {explainText}
          <Button
            variant="outlined"
            onClick={() => {
              settext4(String(0));
              settext5("");
              settext6("自動生成されます");
              setChangeType(false);
              setChanging(false);
              setIsTextinputLock(false);
            }}
          >
            {commandList.length}:追加する
          </Button>
        </>
      )}
      <br />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="集合場所番号"
        value={text4}
        onChange={(e) => settext4(e.target.value)}
        disabled
      />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="集合場所"
        value={text5}
        onChange={(e) => settext5(e.target.value)}
        disabled={isTextinputLock}
      />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="集合場所ID"
        value={text6}
        onChange={(e) => settext6(e.target.value)}
        disabled
      />

      {text1 ? (
        <>
          {changeType ? (
            <>
              {" "}
              <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                  setChanging(true);
                  await updateGatheringPlace(eki_id, text6, {
                    num: Number(text4),
                    text: String(text5),
                  });

                  setTimeout(async () => {
                    await getDataGatheringPlace(eki_id);
                    setChanging(false);
                  }, 1000); // 1秒の遅延
                }}
                style={{ fontSize: 20 }}
                disabled={changing}
              >
                集合場所の変更
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                  setChanging(true);
                  await deleteGatheringPlace(eki_id, text6);
                  setTimeout(async () => {
                    await getDataGatheringPlace(eki_id);
                    setChanging(false);
                  }, 1000); // 1秒の遅延
                }}
                style={{ fontSize: 20 }}
                disabled={changing}
              >
                削除
              </Button>
              {text6 ? (
                <>
                  {/* <ImageSetting /> */}
                  {
                    <ImageSettingGatheringPlace
                      eki_id={eki_id}
                      gatheringPlace_id={text6}
                      imageurl={imageurl}
                      setimageurl={setimageurl}
                    />
                  }
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {" "}
              <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                  setChanging(true);
                  await addGatheringPlace(eki_id, {
                    num: Number(text4),
                    text: String(text5),
                  });
                  setTimeout(async () => {
                    await getDataGatheringPlace(eki_id);
                    setChanging(false);
                  }, 1000); // 1秒の遅延
                }}
                style={{ fontSize: 20 }}
                disabled={changing}
              >
                集合場所の作成
              </Button>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default DeveloperGatheringPlace;
