import React from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  List,
  Divider,
  TextField,
  Button,
} from "@mui/material";

type Props = {
  bgColor: any;
  PostBBS: any;
  BBS: any;
  eventData: any;
  eventParticipantsList: any;
  content: any;
  setContent: any;
  cancelUser: any;
  bookUser: any;
  isParticipation: any;
  userInfo: any;
};

const MenuEventBeforeBook: React.VFC<Props> = ({
  bgColor,
  PostBBS,
  BBS,
  eventData,
  eventParticipantsList,
  content,
  setContent,
  cancelUser,
  bookUser,
  isParticipation,
  userInfo,
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h5" component="h2" gutterBottom>
            {/* イベントID: {eventid} */}
            {eventData.title}
          </Typography>
          <br />
          日程：{eventData.eventDateString}
          <br />
          駅名：{eventData.eki}
          <br />
          <Button
            style={{
              display: "inline-block",
              padding: "10px 10px",
              fontSize: "18px",
              color: "#fff",
              backgroundColor: "#CC9900",
              textDecoration: "none",
              borderRadius: "5px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              navigate("/Shizigatya/" + eventData.ekiID);
            }}
          >
            ガチャ
          </Button>
          <span
            dangerouslySetInnerHTML={{
              __html: eventData.comment,
            }}
          />
          <br />
          <div style={{ color: bgColor.back01 }}> {eventData.id}</div>
          <br />
          {!userInfo.isLogin ? (
            <div style={{ textAlign: "center" }}>
              <br />
              まずは
              <Button
                style={{
                  display: "inline-block",
                  padding: "10px 10px",
                  fontSize: "18px",
                  color: "#fff",
                  backgroundColor: "#CC9900",
                  textDecoration: "none",
                  borderRadius: "5px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => {
                  navigate("/Book/signin");
                }}
              >
                ログイン
              </Button>
              しましょう
            </div>
          ) : isParticipation ? (
            <>ここは表示されないはず</>
          ) : (
            <>
              <Button
                style={{
                  display: "inline-block",
                  padding: "10px 10px",
                  fontSize: "18px",
                  color: "#fff",
                  backgroundColor: bgColor.back06,
                  textDecoration: "none",
                  borderRadius: "5px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s ease",
                  width: "100%",
                }}
                onClick={() => {
                  bookUser(eventData.id, eventData.title, eventData.eventDate);
                }}
              >
                参加
              </Button>
            </>
          )}
          <br />
          <br />
          <br />
          <u>🔳 イベント参加者</u>
          <br />
          {eventParticipantsList.map((item: any, index: any) => (
            <>{item.name}:</>
          ))}
          <br />
          <br />
          <br />
        </Paper>
        <br />
      </Container>
    </>
  );
};
export default MenuEventBeforeBook;
