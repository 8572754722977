import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ReactLoading from "react-loading";

type Props = {
  open: any;
};

const LodingModal: React.VFC<Props> = ({ open }: Props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open} style={{ outline: "none" }}>
        <div
          style={{
            marginTop: "35vh", // 垂直方向の位置調整
            border: 0,
            textAlign: "center", // 水平方向の中央揃え
            width: "fit-content", // コンテンツ幅に合わせる
            marginLeft: "auto", // 左右中央に配置
            marginRight: "auto",
          }}
        >
          <ReactLoading type="bars" />
        </div>
      </Fade>
    </Modal>
  );
};
export default LodingModal;
