import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

type Station = {
  id: string;
  num: number;
  text: string;
  isActive: boolean;
  describe: string;
};

type Props = {
  bgColor: any;
  styletext: any;
  datas: any;
  setDatas: React.Dispatch<React.SetStateAction<any>>;
  routeList: Station[];
};

const centerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  overflow: "hidden", // はみ出た部分を無視
};

const circularMenuStyle: React.CSSProperties = {
  position: "relative",
  width: "90vh",
  height: "90vh",
  // right: "25px",
  overflow: "hidden", // はみ出た部分を無視
};

const stationItemStyle: React.CSSProperties = {
  position: "absolute",
  top: "80vh",
  left: "46%",
  transformOrigin: "center center",
  transition: "transform 0.5s ease-in-out, color 0.5s ease-in-out",
  overflow: "hidden", // はみ出た部分を無視
};

const centerExStyle: React.CSSProperties = {
  position: "absolute",
  top: "40vh",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center", // Centers text horizontally
};

const centerExStyle2: React.CSSProperties = {
  position: "absolute",
  top: "50vh",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center", // Centers text horizontally
  width: "100%",
};

const cellStyle1 = {
  height: "10vh",
};
const cellStyle2 = {
  height: "10vh",
};
const cellStyle3 = {
  height: "2vh",
};

const cellStyle4 = {
  height: "10vh",
  gap: "10px",
};

const EkisentakuView: React.FC<Props> = ({
  bgColor,
  styletext,
  datas,
  setDatas,
  routeList,
}) => {
  const navigate = useNavigate();
  const [currentAngle, setCurrentAngle] = useState({
    angle: 1080,
    num: 0,
    text: "東京",
    id: "XizOo6WOXHjMK01o6htz",
    describe: "新幹線と地下鉄の中心で日本最大の交通拠点",
  });

  const nextStation = () => {
    let nextnum = currentAngle.num;
    let nextangle = currentAngle.angle;
    do {
      nextnum = (nextnum - 1 + routeList.length) % routeList.length;
      nextangle = nextangle + 360 / routeList.length;
    } while (!routeList[nextnum].isActive);

    setCurrentAngle((prev) => ({
      angle: nextangle,
      num: nextnum,
      text: routeList[nextnum].text,
      id: routeList[nextnum].id,
      describe: routeList[nextnum].describe,
    }));
  };
  const prevStation = () => {
    let nextnum = currentAngle.num;
    let nextangle = currentAngle.angle;
    do {
      nextnum = (nextnum + 1) % routeList.length;
      nextangle = nextangle - 360 / routeList.length;
    } while (!routeList[nextnum].isActive);

    setCurrentAngle((prev) => ({
      angle: nextangle,
      num: nextnum,
      text: routeList[nextnum].text,
      id: routeList[nextnum].id,
      describe: routeList[nextnum].describe,
    }));
  };

  return (
    <div style={centerStyle}>
      <br />
      <div style={circularMenuStyle}>
        {routeList.map((item, index) => {
          const angle = (360 / routeList.length) * index + currentAngle.angle;
          const reverseIndex =
            (routeList.length -
              (Math.round(currentAngle.angle / (360 / routeList.length)) %
                routeList.length)) %
            routeList.length;
          // console.log(reverseIndex, routeList.length);
          const isSelected = index === reverseIndex;
          return (
            <div
              key={item.id}
              style={{
                ...stationItemStyle,
                transform: `rotate(${angle}deg) translate(0, -55vh) rotate(-${angle}deg)`,
              }}
            >
              <Button
                variant="outlined"
                style={{
                  fontSize: isSelected ? "18px" : "18px",
                  marginTop: isSelected ? "0px" : "0px",

                  padding: "1px 2px",
                  minWidth: "auto",
                  color: !item.isActive
                    ? "#D3D3D3"
                    : isSelected
                    ? "white"
                    : "black",
                  border: "none",
                  textAlign: "center", // 中央揃え
                  display: "flex", // フレックスボックスを使用
                  justifyContent: "center", // 水平方向の中央揃え
                }}
                disabled={!item.isActive}
                onClick={() => {
                  setDatas({
                    place: { num: item.num, text: item.text, id: item.id },
                    step: datas.step,
                    moveList: [],
                  });
                  navigate("/Shizigatya/" + item.id);
                }}
              >
                {item.text}
              </Button>
            </div>
          );
        })}
        <div style={centerExStyle}>
          <table>
            <tbody>
              <tr>
                <td style={cellStyle1}>
                  <div style={{ color: "red", fontSize: "26px" }}>
                    {currentAngle.text}
                  </div>
                </td>
              </tr>
              <tr>
                <td style={cellStyle2}></td>
              </tr>
              <tr>
                <td style={cellStyle1}>
                  <div
                    style={{
                      color: "skyblue",
                      fontSize: "26px",

                      overflow: "hidden", // テキストが枠を超える場合は非表示に
                      textOverflow: "ellipsis", // 省略記号を表示
                      display: "-webkit-box", // Webkitボックスを使用
                      WebkitLineClamp: 4, // 表示する行数を制限
                      WebkitBoxOrient: "vertical", // ボックスの方向を縦に設定
                    }}
                  >
                    {currentAngle.describe}
                  </div>
                </td>
              </tr>
              <tr>
                <td style={cellStyle3}></td>
              </tr>
              <tr>
                <td style={cellStyle4}>
                  <table style={centerExStyle2}>
                    <tbody>
                      <tr>
                        <td>
                          <Button
                            variant="outlined"
                            style={{ border: "none", fontWeight: "bold" }}
                            onClick={nextStation}
                          >
                            ＜内回り
                          </Button>
                        </td>
                        <td>
                          <Button
                            variant="outlined"
                            style={{
                              border: "none",
                              fontSize: 20,
                              fontWeight: "bold",
                              minWidth: "150px",
                            }}
                            onClick={() => {
                              setDatas({
                                place: {
                                  num: currentAngle.num,
                                  text: currentAngle.text,
                                  id: currentAngle.id,
                                },
                                step: datas.step,
                                moveList: [],
                              });
                              navigate("/Shizigatya/" + currentAngle.id);
                            }}
                          >
                            選択
                          </Button>
                        </td>
                        <td>
                          <Button
                            variant="outlined"
                            style={{ border: "none", fontWeight: "bold" }}
                            onClick={prevStation}
                          >
                            外回り＞
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EkisentakuView;
