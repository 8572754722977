import { Typography } from "@mui/material";
// import { useStyles } from "../../Styles";
import Link from "@mui/material/Link";

import React, { useReducer, useEffect, useState } from "react";

import lamactPic from "./image/Lamact.png";

type Props = {
  bgColor: any;
  styletext: any;
};
const Footer: React.VFC<Props> = ({ bgColor, styletext }: Props) => {
  //   const classes = useStyles();
  const [marginOne, setMarginOne] = useState("33px");
  const [marginTwo, setMarginTwo] = useState("52px");

  //   useEffect(() => {
  //     console.log("uE_Footer");
  //     if (props.styletext.isbig) {
  //       setMarginOne("20px");
  //       setMarginTwo("20px");
  //     } else {
  //       setMarginOne("33px");
  //       setMarginTwo("52px");
  //     }
  //   }, [props.styletext.isbig]);
  return (
    <footer
      style={{
        backgroundColor: bgColor.back04, // 背景色を指定
      }}
    >
      <br />
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            window.open("https://lamact.com/");
          }}
          style={{
            color: "#000000",
            width: "170px",
            height: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${lamactPic})`,
            backgroundSize: "170px 48px",
          }}
        ></div>
      </Typography>
      <br />

      <Typography variant="body2" align="center" style={{ color: "#555555" }}>
        <Link
          color="inherit"
          href="https://lonpa.net/Terms"
          style={{
            marginLeft: "20px",
            marginRight: "60px",
            textDecoration: "none",
          }}
        >
          利用規約
        </Link>
        <Link
          color="inherit"
          href="https://lonpa.net/Law"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
            textDecoration: "none",
          }}
        >
          特定商取引法に基づく表記
        </Link>

        <Link
          color="inherit"
          href="https://line.me/R/ti/p/@729gokgs"
          style={{
            marginLeft: "20px",
            marginRight: marginOne,
            textDecoration: "none",
          }}
        >
          お問い合わせ
        </Link>
        <Link
          color="inherit"
          href="https://lonpa.net/PrivacyPolicy"
          style={{
            marginLeft: "20px",
            marginRight: "52px",
            textDecoration: "none",
          }}
        >
          プライバシーポリシー
        </Link>
        <br />
        <br />
      </Typography>
    </footer>
  );
};
export default Footer;
