import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PastEvents from "./PastEvents";

type Props = {
  bgColor: any;
  styletext: any;
  // isLogin: any;
  userInfo: any;
  eventPastList: any;
  eventFutureList: any;
  cancelUser: any;
  bookUser: any;
  errorText: any;
};

const MenuEvent: React.VFC<Props> = ({
  bgColor,
  styletext,
  // isLogin,
  userInfo,

  eventPastList,
  eventFutureList,
  cancelUser,
  bookUser,
  errorText,
}: Props) => {
  const navigate = useNavigate();
  const cellStyle: React.CSSProperties = {
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "14px",
    textAlign: "center", // 水平方向の中央揃え
    // width: "fit-content", // コンテンツ幅に合わせる
    marginLeft: "auto", // 左右中央に配置
    marginRight: "auto",
  };
  return (
    <div>
      <div style={{ textAlign: "center", backgroundColor: bgColor.back05 }}>
        🔳 開催予定イベント
      </div>
      <br />
      <table
        style={{
          width: styletext.displayWidth,
        }}
      >
        <tbody>
          {eventFutureList.map((item: any, index: any) => (
            <tr>
              <td style={cellStyle}>{item.title}</td>

              {/* {item.disabled ? <>予約済</> : <>未予約</>} */}

              <td style={cellStyle}>
                <Button
                  style={{
                    display: "inline-block",
                    padding: "10px 10px",
                    fontSize: "18px",
                    color: "#fff",
                    backgroundColor: bgColor.back06,
                    textDecoration: "none",
                    borderRadius: "5px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease",
                  }}
                  onClick={() => {
                    navigate("/Event/" + item.id);
                  }}
                >
                  詳細
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <div style={{ textAlign: "center", backgroundColor: bgColor.back05 }}>
        🔳 過去イベントのレポート
      </div>
      <br />
      <br />
      <PastEvents eventPastList={eventPastList} />
    </div>
  );
};

export default MenuEvent;
