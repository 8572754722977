import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";

import { Datas, Route, Command } from "../../../common/types";
import ImageSetting from "../../../common/lib/imageSetting";

import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
} from "@mui/material";

import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Timestamp } from "firebase/firestore"; // FirestoreのTimestamp型をインポート
import {
  getCommands,
  addCommand,
  updateCommand,
  deleteCommand,
  updateData,
} from "../../../common/firebaseHelper/FirebaseTransactionHelper";

import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import DeveloperView from "./DeveloperView";
import LodingModal from "../../Modal/LodingModal/LodingModal";

type Props = {
  bgColor: any;
  styletext: any;
  userInfo: any;
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
};

const Developer: React.VFC<Props> = ({
  datas,
  setDatas,
  routeList,
  bgColor,
  styletext,
  userInfo,
}: Props) => {
  const [isParamReady, setIsParamReady] = useState(false);
  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  useEffect(() => {
    console.log("uE_Developer");
    const f = async () => {
      setIsParamReady(userInfo.adminFlag);
      setLodingmodalopen(!userInfo.adminFlag);

      console.log(userInfo.adminFlag);
    };
    f();
  }, []);

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };
  return (
    <div>
      {isParamReady ? (
        <>
          <Navbar
            bgColor={bgColor}
            styletext={styletext}
            // setOpendMenu={setOpendMenu}

            userInfo={userInfo}
          />

          <div
            style={
              styletext.windowWidth > 1200
                ? styles1.container
                : styles2.container
            }
          >
            <DeveloperView
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
              bgColor={bgColor}
              styletext={styletext}
              userInfo={userInfo}
            />
          </div>

          <Footer bgColor={bgColor} styletext={styletext} />
        </>
      ) : (
        <>loding</>
      )}
      <LodingModal open={lodingmodalopen} />
    </div>
  );
};
export default Developer;
