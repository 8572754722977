import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";

import { Button } from "@mui/material";
import { otherApp } from "../../../common/firebaseHelper/firebase";

import {
  getEvents,
  setEventParticipants,
  deleteEventParticipants,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";
import { getUserPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";
import {
  setUserPrivateBook,
  getUserPrivateBook,
  deleteUserPrivateBook,
  getUserPrivatePlan,
  setUserPrivatePlan,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPrivate";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

import LodingBook from "./LodingBook";

import BookView from "./BookView";

import LodingModal from "../../Modal/LodingModal/LodingModal";

type Props = {
  bgColor: any;
  styletext: any;
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
  userInfo: any;
};

const Book: React.FC<Props> = ({
  bgColor,
  styletext,
  datas,
  setDatas,
  routeList,
  userInfo,
}) => {
  const navigate = useNavigate();
  let { opendmenu } = useParams();

  const [eventList, setEventList] = useState([]);
  const [eventPastList, setEventPastList] = useState([]);
  const [eventFutureList, setEventFutureList] = useState([]);

  const [eventJoinList, setEventJoinList] = useState([]);
  const [eventJoinPastList, setEventJoinPastList] = useState([]);
  const [eventJoinFutureList, setEventJoinFutureList] = useState([]);
  const [eventPlanList, setEventPlanList] = useState([]);

  const [opendMenu, setOpendMenu] = useState("event");
  // const [isMemberOpen, setIsMemberOpen] = useState(false);

  const [changeCount, setChangeCount] = useState(0);
  const [errorText, setErrorText] = useState("");

  const [isParamReady, setIsParamReady] = useState(false);

  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  useEffect(() => {
    console.log("uE_Book");
    const f = async () => {
      const querySnapshot = await getEvents();
      let lists: any = [];
      querySnapshot.forEach((doc: any) => {
        lists.push({
          id: doc.id,
          title: doc.data().title,
          eventDate: doc.data().eventDate,
          priority: doc.data().priority,
          isActive: true,
          disabled: false,
        });
      });
      setEventList(lists);

      const todaydate = new Date();
      const listPast: any = [];
      const listFuture: any = [];

      lists.forEach((event: any) => {
        if (event.eventDate) {
          const eventDate = event.eventDate.toDate();
          if (eventDate < todaydate) {
            listPast.push(event);
          } else {
            listFuture.push(event);
          }
        }
      });
      setEventPastList(listPast);
      setEventFutureList(listFuture);

      const usercheck = onAuthStateChanged(getAuth(otherApp), async (user) => {
        // console.log("通過");
        // console.log(user)
        if (user) {
          const querySnapshot2 = await getUserPrivateBook(user.uid);

          // ★追加: futureList と pastList を初期化
          let futureList: any = []; // ★追加
          let pastList: any = []; // ★追加
          const todaydate = new Date(); // ★追加

          //ログイン中のアカウントで，選択中のlonpa一覧
          let lists2: any = [];
          querySnapshot2.forEach((doc: any) => {
            const eventData = doc.data();
            console.log(eventData);
            // const eventDate = eventData.eventDate.toDate(); // Firestore の日付を変換

            const event = {
              id: doc.data().id,
              title: doc.data().title,
              // eventDate: eventDate,
            };

            lists2.push(event);
            // // 未来と過去のイベントを分類
            // if (eventDate >= todaydate) {
            //   futureList.push(event);
            // } else {
            //   pastList.push(event);
            // }
          });
          setEventJoinList(lists2);

          setEventJoinPastList(pastList);
          setEventJoinFutureList(futureList);

          // EventList と EventJoinList を突合
          const updatedLists = lists.map((event: any) => {
            const isJoined = lists2.some(
              (joined: any) => joined.id === event.id
            );
            if (isJoined) {
              return { ...event, disabled: true };
            }
            return event;
          });

          // 更新された EventList をセット
          setEventList(updatedLists);

          const updatedlistPast: any = [];
          const updatedlistFuture: any = [];
          updatedLists.forEach((event: any) => {
            if (event.eventDate) {
              const eventDate = event.eventDate.toDate();
              if (eventDate < todaydate) {
                updatedlistPast.push(event);
              } else {
                updatedlistFuture.push(event);
              }
            }
          });
          setEventPastList(updatedlistPast);
          setEventFutureList(updatedlistFuture);

          const querySnapshot3 = await getUserPrivatePlan(user.uid);
          let lists3: any = [];
          querySnapshot3.forEach((doc: any) => {
            // console.log(doc.id);
            lists3.push({
              id: doc.data().id,
              title: doc.data().title,
            });
          });
          setEventPlanList(lists3);
        }
      });
      setIsParamReady(true);
      setLodingmodalopen(false);

      return () => usercheck();
    };
    f();
  }, [changeCount]);

  useEffect(() => {
    setOpendMenu(opendmenu ?? "event");
  }, [opendmenu]);

  const incrementChangeCount = () => {
    setChangeCount(changeCount + 1);
  };

  // 参加キャンセル時に呼ばれる
  const cancelUser = async (event_id: string) => {
    const auth = getAuth(otherApp);
    const user = auth.currentUser;
    if (user) {
      await deleteEventParticipants(event_id, user.uid);
      await deleteUserPrivateBook(user.uid, event_id);
      incrementChangeCount();
    } else {
      // console.log("ログインしていない");
    }
  };

  //参加時に呼ばれる
  const bookUser = async (
    event_id: string,
    event_title: string,
    event_eventDate: Date
  ) => {
    const auth = getAuth(otherApp);
    const user = auth.currentUser;
    console.log("koko");
    console.log(event_eventDate);
    if (user) {
      let doc = await getUserPublic(user.uid);
      if (typeof doc.data() !== "undefined") {
        if (typeof doc.data().name !== "undefined") {
          await setEventParticipants(event_id, user.uid, {
            uid: user.uid,
            name: doc.data().name,
          });
          await setUserPrivateBook(user.uid, event_id, {
            id: event_id,
            title: event_title,
            eventDate: event_eventDate,
          });
          incrementChangeCount();
        } else {
          console.log("name undfined");
          setErrorText(
            "ユーザに名前が設定されていないようです。プロフィール欄で設定してください。"
          );
        }
      } else {
        console.log("data undfined");
        setErrorText(
          "ユーザに名前が設定されていないようです。プロフィール欄で設定してください。"
        );
      }
    } else {
      // console.log("ログインしていない");
      // navigate("/Signin/");
      setOpendMenu("signin");
    }
  };

  const centerStyle = {
    // display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    height: "100vh", //画面の高さいっぱいに表示する場合
  };

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };

  return (
    <div style={centerStyle}>
      {isParamReady ? (
        <>
          <Navbar
            bgColor={bgColor}
            styletext={styletext}
            // setOpendMenu={setOpendMenu}

            userInfo={userInfo}
          />

          <div
            style={
              styletext.windowWidth > 1200
                ? styles1.container
                : styles2.container
            }
          >
            <BookView
              bgColor={bgColor}
              styletext={styletext}
              opendMenu={opendMenu}
              userInfo={userInfo}
              incrementChangeCount={incrementChangeCount}
              eventPastList={eventPastList}
              eventFutureList={eventFutureList}
              cancelUser={cancelUser}
              bookUser={bookUser}
              errorText={errorText}
              eventJoinList={eventJoinList}
              eventJoinPastList={eventJoinPastList}
              eventJoinFutureList={eventJoinFutureList}
              eventPlanList={eventPlanList}
              setOpendMenu={setOpendMenu}
            />
          </div>

          <Footer bgColor={bgColor} styletext={styletext} />
        </>
      ) : (
        <LodingBook bgColor={bgColor} />
      )}
      <LodingModal open={lodingmodalopen} />
    </div>
  );
};

export default Book;
