export const markdown = `
# 山手線の駅ウォーキング企画
みんなと話しながら、わいわい歩きたい方におススメです。
年代の近い人と仲良くなりたくて、このイベントを作成しました。
20代30代の皆さん、趣味や好きなことを一緒に共有しませんか？
ほとんどの方が一人参加です！お気軽にご参加ください！

## イベントの流れ
1. **【集合】** - 集合場所につきましたら同じポーズをとりあってお互いを確認しましょう。
2. **【グループ分け】** - 参加人数から、1グループ3-4人のグループ分けを行います。
3. **【自己紹介】** - 簡単に自己紹介しましょう。
4. **【散歩スタート】** - 各グループで駅周辺を散策しましょう。行き場所は行先ガチャアプリを用いて、集まったメンバーで選びます。
5. **【解散】** - 自由解散ですが、2時間を目安に区切りましょう。

## 参加条件
- 性別を問わずコミュニケーションが取れる方
- ルールやマナーを守れる方
- 営業、勧誘、ナンパ目的でない方

## 注意事項
- スケジュールは状況に応じて変更になる場合があります。
- 天候不良で開催が困難な時はイベントを中止する場合があります。
- 遅刻をした場合、イベントに参加できなくなります。（その場合の返金はありません）
- 時間になり次第、お散歩を出発いたします。
- イベント中に発生した費用については、ご自分で飲食した分をお支払いください。

## 禁止事項
- 勧誘・営業行為は禁止です。
- 迷惑行為や他参加者への誹謗中傷も禁止です。
イベント中やイベント後に上記の行為があった場合には、主催者にご連絡ください。
 `;
