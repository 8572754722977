import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_live_51Jxlu6LAUxEuQidCBW6ffghhtmiCDkGfELX0ZUiQTdx4Y0XPsaiyvWjDXot4ZgGKUMSEwtVibxseqvOIPUxa9mgh00erAWWxPa"
    );
  }
  return stripePromise;
};
export default initializeStripe;
