import React from "react";

type Props = {
  bgColor: any;
};

const MenuEventCreater: React.VFC<Props> = ({ bgColor }: Props) => {
  return <></>;
};
export default MenuEventCreater;
