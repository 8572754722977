import React from "react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";

import { Button, TextField, Checkbox, Link } from "@mui/material";

import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { otherApp } from "../../../common/firebaseHelper/firebase";

import { addEvent } from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";
import { getUserPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";
import {
  setUserPrivateBook,
  getUserPrivateBook,
  deleteUserPrivateBook,
  getUserPrivatePlan,
  setUserPrivatePlan,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPrivate";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Userpage from "../Userpage/Userpage";

import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import ChoiceEki from "./ChoiceEki";

import CreateEventWrite from "./CreateEventWrite";
import CreateEventWrite2 from "./CreateEventWrite2";
import CreateEventConfilm from "./CreateEventConfilm";

import { markdown } from "./teikei";

type Props = {
  bgColor: any;
  styletext: any;
  // avatar: any;
  // isLogin: any;
  userInfo: any;

  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
};

const CreateEvent: React.FC<Props> = ({
  bgColor,
  styletext,
  datas,
  setDatas,
  routeList,
  // isLogin,
  // avatar,
  userInfo,
}) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState(markdown);
  const [choiceEki, setChoiceEki] = useState({
    num: 0,
    text: "東京",
    id: "XizOo6WOXHjMK01o6htz",
    describe: "新幹線と地下鉄の中心で日本最大の交通拠点",
  });
  const [choiceDate, setChoiceDate] = useState<Dayjs | null>(dayjs());
  const [choiceTime, setChoiceTime] = useState<Dayjs | null>(dayjs());
  const [termcheck, setTermcheck] = useState(false);
  // const [isWrote, setIsWrote] = useState(false);
  const [opendMenu, setOpendMenu] = useState("one");

  const [gatheringPlaceList, setGatheringPlaceList] = useState([]);
  const [choiceGatheringPlace, setChoiceGatheringPlace] = useState([]);

  useEffect(() => {
    const f = async () => {};
    f();
  }, []);

  function insertNewlineIndicators(text: string) {
    return text.replace(/\n/g, "\\n"); // 改行を \n に置換
  }

  const createNewPlan = async (
    event_eki: string,
    event_ekiid: string,
    event_date: Date,
    event_comment: string,
    event_choiceGatheringPlace: any
  ) => {
    const modifiedText_event_comment = insertNewlineIndicators(event_comment);
    // console.log(modifiedText_event_comment); // 出力: これは最初の行\nこれは次の行\nさらに別の行

    const auth = getAuth(otherApp);
    const user: any = auth.currentUser;
    if (user) {
      const docRef = await addEvent({
        title: event_eki + "散歩",
        eki: event_eki,
        ekiID: event_ekiid,
        eventDate: event_date,
        comment: modifiedText_event_comment,
        creater: user.uid,
        EventBBSCount: 0,
        ParticipantsCount: 0,
        priority: 1,
        gatheringPlace: event_choiceGatheringPlace,
      });
      console.log(docRef.id);
      await setUserPrivatePlan(user.uid, docRef.id, {
        id: docRef.id,
        eki: event_eki,
        title: event_eki + "散歩",
        eventDate: event_date,
      });
      navigate("/Book/");
    } else {
      console.log("ログインしていないか、あるいはタイトルが空です。");
    }
  };

  const handleChangeTerm = async () => {
    if (termcheck) {
      setTermcheck(false);
      // setIscheck({ ...ischeck, 1: false });
    } else {
      setTermcheck(true);
      // setIscheck({ ...ischeck, 1: true });
    }
  };

  const centerStyle = {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    height: "100vh", //画面の高さいっぱいに表示する場合
  };

  return (
    <>
      {opendMenu === "one" ? (
        <CreateEventWrite
          bgColor={bgColor}
          styletext={styletext}
          datas={datas}
          setDatas={setDatas}
          routeList={routeList}
          userInfo={userInfo}
          choiceEki={choiceEki}
          setChoiceEki={setChoiceEki}
          choiceDate={choiceDate}
          setChoiceDate={setChoiceDate}
          choiceTime={choiceTime}
          setChoiceTime={setChoiceTime}
          title={title}
          setTitle={setTitle}
          handleChangeTerm={handleChangeTerm}
          termcheck={termcheck}
          setOpendMenu={setOpendMenu}
        />
      ) : opendMenu === "two" ? (
        <>
          <CreateEventWrite2
            bgColor={bgColor}
            styletext={styletext}
            datas={datas}
            setDatas={setDatas}
            routeList={routeList}
            userInfo={userInfo}
            choiceEki={choiceEki}
            setChoiceEki={setChoiceEki}
            choiceDate={choiceDate}
            setChoiceDate={setChoiceDate}
            choiceTime={choiceTime}
            setChoiceTime={setChoiceTime}
            title={title}
            setTitle={setTitle}
            handleChangeTerm={handleChangeTerm}
            termcheck={termcheck}
            setOpendMenu={setOpendMenu}
            choiceGatheringPlace={choiceGatheringPlace}
            setChoiceGatheringPlace={setChoiceGatheringPlace}
            gatheringPlaceList={gatheringPlaceList}
            setGatheringPlaceList={setGatheringPlaceList}
          />
        </>
      ) : opendMenu === "confilm" ? (
        <CreateEventConfilm
          bgColor={bgColor}
          styletext={styletext}
          datas={datas}
          setDatas={setDatas}
          routeList={routeList}
          // userInfo={userInfo}
          choiceEki={choiceEki}
          choiceDate={choiceDate}
          choiceTime={choiceTime}
          title={title}
          termcheck={termcheck}
          // setIsWrote={setIsWrote}
          createNewPlan={createNewPlan}
          setOpendMenu={setOpendMenu}
          choiceGatheringPlace={choiceGatheringPlace}
        />
      ) : (
        <>ここは表示されないはず</>
      )}
    </>
  );
};

export default CreateEvent;
