import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import {
  TimePicker,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { otherApp } from "../../../common/firebaseHelper/firebase";
import moment from "moment";

import {
  getEvent,
  updateEvent,
  deleteEvent,
  getEventParticipants,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import { deleteUserPrivatePlan } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPrivate";

import { addUserPublicNotice } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";

import { getAuth } from "firebase/auth";

import EditEventView from "./EditEventView";

import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

type Props = {
  bgColor: any;
  styletext: any;
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
  userInfo: any;
};

const EditEvent: React.FC<Props> = ({
  bgColor,
  styletext,
  datas,
  setDatas,
  routeList,
  // isLogin,
  // avatar,
  userInfo,
}) => {
  let { eventid } = useParams();
  const navigate = useNavigate();
  const [eventData, setEventData] = useState({
    title: "",
    comment: "",
    eki: "",
    eventDate: new Date(0),
    eventDateString: "",
  });
  // const [title, setTitle] = useState("");
  const [todayDate, setTodayDate] = useState<Date>(new Date(0));
  const [todayString, setTodayString] = useState("");
  // const [eventDate, setEventDate] = useState<Date>(new Date(0));
  // const [eventDateString, setEventDateString] = useState("");

  const parseAsMoment = (dateTimeStr: any) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  useEffect(() => {
    console.log("ue_EditEvent");
    const f = async () => {
      //現在日付データ作成
      const todaydate = new Date();
      const todaystring = parseAsMoment(todaydate)
        .format("YYYY/MM/DD HH:mm")
        .toString();

      setTodayDate(todaydate);
      setTodayString(todaystring);

      //イベントの情報を取得
      let doc = await getEvent(eventid);
      // console.log(doc.data());

      if (doc.data().eventDate) {
        const eventdatestring = parseAsMoment(doc.data().eventDate.toDate())
          .format("YYYY/MM/DD HH:mm")
          .toString();

        // setEventDate();
        // setEventDateString(eventdatestring);

        setEventData({
          title: doc.data().title,
          comment: doc.data().comment,
          eki: doc.data().eki,
          eventDate: doc.data().eventDate.toDate(),
          eventDateString: eventdatestring,
        });
      } else {
        //日付が設定されていなければ、初期値として本日の日付を入れる
        setEventData({
          title: doc.data().title,
          comment: doc.data().comment,
          eki: doc.data().eki,
          eventDate: todaydate,
          eventDateString: todaystring,
        });
      }
    };
    f();
  }, []);

  const updatePlan = async () => {
    const auth = getAuth(otherApp);
    const user: any = auth.currentUser;
    if (user && eventData.title) {
      const docRef = await updateEvent(eventid, {
        title: eventData.title,
        eventDate: eventData.eventDate,
      });
      navigate("/Book/");
    } else {
      console.log("ログインしていないか、あるいはタイトルが空です。");
    }
  };

  const deletePlan = async () => {
    const auth = getAuth(otherApp);
    const user: any = auth.currentUser;
    // console.log(eventData);
    if (user) {
      //イベント参加者一覧
      const querySnapshot3 = await getEventParticipants(eventid);
      querySnapshot3.forEach((doc: any) => {
        //event参加予定者にイベント中止のお知らせ
        addUserPublicNotice(doc.data().uid, {
          date: todayString,
          text: eventData.title + "は中止になりました",
        });
      });

      //eventを削除
      const docRef = await deleteEvent(eventid);
      //主催者の開催イベント一覧から削除
      const docRef2 = await deleteUserPrivatePlan(user.uid, eventid);
      navigate("/Book/");
    } else {
      console.log("ログインしていない。");
    }
  };

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };

  return (
    // <div style={centerStyle}>
    <>
      <Navbar
        bgColor={bgColor}
        styletext={styletext}
        // setOpendMenu={setOpendMenu}

        userInfo={userInfo}
      />

      <div
        style={
          styletext.windowWidth > 1200 ? styles1.container : styles2.container
        }
      >
        <EditEventView
          bgColor={bgColor}
          styletext={styletext}
          eventData={eventData}
          setEventData={setEventData}
          // eventDate={eventDate}
          // setEventDateString={setEventDateString}
          // setEventDate={setEventDate}
          // title={title}
          // setTitle={setTitle}
          updatePlan={updatePlan}
          deletePlan={deletePlan}
        />
      </div>

      <Footer bgColor={bgColor} styletext={styletext} />
    </>
    // </div>
  );
};

export default EditEvent;
