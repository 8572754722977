import MenuEvent from "./MenuEvent";
import MenuJoin from "./MenuJoin";
import MenuPlan from "./MenuPlan";
import MenuProfile from "./MenuProfile";
import MenuSignin from "./MeunSignin";
import MenuSignup from "./MenuSignup";

type Props = {
  bgColor: any;
  styletext: any;
  opendMenu: any;

  // isLogin: any;
  userInfo: any;
  incrementChangeCount: any;
  eventPastList: any;
  eventFutureList: any;
  cancelUser: any;
  bookUser: any;
  errorText: any;

  eventJoinList: any;
  eventJoinPastList: any;
  eventJoinFutureList: any;
  eventPlanList: any;
  setOpendMenu: any;
};

const BookView: React.VFC<Props> = ({
  bgColor,
  styletext,
  opendMenu,
  // isLogin,
  userInfo,

  incrementChangeCount,
  eventPastList,
  eventFutureList,
  cancelUser,
  bookUser,
  errorText,
  eventJoinList,
  eventJoinPastList,
  eventJoinFutureList,
  eventPlanList,
  setOpendMenu,
}: Props) => {
  return (
    <>
      <div
        style={{
          fontFamily: "'Roboto', sans-serif",
          // backgroundColor: "#f4f4f4",
          color: "#333",
          margin: 0,
        }}
      >
        <h1
          style={{
            fontFamily: "'Playfair Display', serif",
            fontSize: "35px",
            fontWeight: 700,
            color: "#2c3e50",
            textAlign: "center",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "20px",
          }}
        >
          山手散歩会
        </h1>

        <p
          style={{
            fontSize: "17px",
            lineHeight: 1.6,
            // textAlign: "justify",
            textAlign: "center",
          }}
        >
          <span
            style={{
              backgroundColor: bgColor.back05,
              padding: "0 5px",
              borderRadius: "3px",
            }}
          >
            イベント
          </span>
          を見つけて、参加してみよう！
        </p>

        <br />
        <div>
          {opendMenu === "profile" ? (
            <MenuProfile
              bgColor={bgColor}
              styletext={styletext}
              userInfo={userInfo}
              incrementChangeCount={incrementChangeCount}
              setOpendMenu={setOpendMenu}
            />
          ) : opendMenu === "event" ? (
            <MenuEvent
              bgColor={bgColor}
              styletext={styletext}
              userInfo={userInfo}
              // eventList={eventList}
              eventPastList={eventPastList}
              eventFutureList={eventFutureList}
              cancelUser={cancelUser}
              bookUser={bookUser}
              errorText={errorText}
            />
          ) : opendMenu === "join" ? (
            <MenuJoin
              bgColor={bgColor}
              styletext={styletext}
              userInfo={userInfo}
              eventJoinList={eventJoinList}
              eventJoinPastList={eventJoinPastList}
              eventJoinFutureList={eventJoinFutureList}
              cancelUser={cancelUser}
              setOpendMenu={setOpendMenu}
            />
          ) : opendMenu === "plan" ? (
            <MenuPlan
              bgColor={bgColor}
              styletext={styletext}
              userInfo={userInfo}
              eventPlanList={eventPlanList}
              setOpendMenu={setOpendMenu}
            />
          ) : opendMenu === "signin" ? (
            <MenuSignin
              bgColor={bgColor}
              styletext={styletext}
              setOpendMenu={setOpendMenu}
              incrementChangeCount={incrementChangeCount}
            />
          ) : opendMenu === "signup" ? (
            <MenuSignup
              bgColor={bgColor}
              styletext={styletext}
              setOpendMenu={setOpendMenu}
              incrementChangeCount={incrementChangeCount}
            />
          ) : (
            <div>選んでみよう</div>
          )}
        </div>
      </div>
      <br />
      <br />
      <br />
    </>
  );
};
export default BookView;
